import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faTimes } from '@fortawesome/free-solid-svg-icons';

import FileUpload from '../FileUpload/FileUpload';
import Progressbar from '../Progressbar/Progressbar';
import ValidationMessage from './ValidationMessage';
import { LinkButton } from '../Button';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 0.25rem;
`;

const Label = styled.span`
  color: var(--primary-color);
  margin-right: 1rem;
`;

export default function File({ errors = [], field, files = [], name, onClick, textButton = 'Delete', ...props }) {
  const error = errors.find((e) => e.field === (field || name));

  return (
    <>
      {files.map((file) => (
        <Wrapper key={file.name}>
          <Label>
            <FontAwesomeIcon icon={faFile} /> {file.name}
          </Label>
          <Progressbar progress={file.progress} hideLabel={file.progress >= 100} />
          {file.progress >= 100 && (
            <LinkButton variant="secondary" size="sm" onClick={onClick} type="button">
              <FontAwesomeIcon icon={faTimes} /> {textButton}
            </LinkButton>
          )}
        </Wrapper>
      ))}
      <FileUpload {...props} />
      {error !== undefined && <ValidationMessage errors={error.messages} />}
    </>
  );
}

File.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      messages: PropTypes.array,
    })
  ),
  field: (props, propName, componentName) => {
    if (
      props.errors.length > 0 &&
      (props.name === undefined || typeof props.name !== 'string') &&
      (props.field === undefined || typeof props.field !== 'string')
    ) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Field is required when errors are present`
      );
    }
  },
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      progress: PropTypes.number.isRequired,
    })
  ),
  name: PropTypes.string,
  onClick: PropTypes.func,
  textButton: PropTypes.string,
};
