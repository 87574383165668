import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';
import { Transition } from 'react-transition-group';

import { Backdrop, Body, Content, Dialog, Footer, Header, duration } from './Components';

export default function Modal({ noPadding = false, ...props }) {
  const node = useRef();
  const backdrop = useRef();

  const onKeyDown = ({ keyCode }) => {
    if (keyCode === 27) props.onClose();
  };

  const onOutSideClick = ({ target }) => {
    if (backdrop.current.contains(target)) props.onClose();
  };

  return createPortal(
    <Transition in={props.isOpen} timeout={{ enter: 0, exit: duration }} nodeRef={node} mountOnEnter unmountOnExit>
      {(state) => (
        <FocusTrap>
          <Dialog open onClick={onOutSideClick} onKeyDown={onKeyDown} role="dialog">
            <Content noPadding={noPadding} small={props.small} state={state}>
              {props.children}
            </Content>
            <Backdrop ref={backdrop} state={state} />
          </Dialog>
        </FocusTrap>
      )}
    </Transition>,
    document.body
  );
}

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  onClose: PropTypes.func.isRequired,
  noPadding: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
};
