import styled from 'styled-components';

import Checkbox from './Checkbox';
import DateTimePicker from './DateTimePicker';
import File from './File';
import Form from './Form';
import Input from './Input';
import InputGroup from './InputGroup';
import RadioButtonGroup from './RadioButtonGroup';
import Select from './Select';
import TextArea from './TextArea';
import TextEditor from './TextEditor';

Form.Group = styled.div`
  position: relative;
  margin-bottom: 1.5rem;

  ${({ password }) =>
    password &&
    `
  input::-webkit-credentials-auto-fill-button {
    position: absolute;
    right: 40px;
  }`}

  ${({ spaceBetween }) => spaceBetween && 'align-items: center; display: flex; justify-content: space-between;'}
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}rem;`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}rem;`}
  ${({ noMargin }) => noMargin && 'margin-bottom: 0;'}
  ${({ gap = 0.5, row }) => row && `align-items: center; display: flex; gap: ${gap}rem;`}
  ${({ small }) => small && 'max-width: 348px;'}
`;

Form.Label = styled.label`
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;

  ${({ thick }) => thick && 'font-weight: 500;'}
`;

Form.Checkbox = Checkbox;
Form.DateTimePicker = DateTimePicker;
Form.File = File;
Form.Input = Input;
Form.InputGroup = InputGroup;
Form.Select = Select;
Form.RadioButtonGroup = RadioButtonGroup;
Form.TextArea = TextArea;
Form.TextEditor = TextEditor;

export default Form;
