import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Btn } from './Button';

const StyledLinkButton = styled(Btn)`
  background-color: transparent;
  color: ${({ variant }) => `var(--${variant}-color)`};

  &:hover {
    ${({ variant }) =>
      `border-color: hsl(
        var(--${variant}-color-h),
        var(--${variant}-color-s),
        calc(var(--${variant}-color-l) - 10%)
      );`}
    text-decoration: underline;
  }
`;

export default function LinkButton({ variant = 'primary', ...props }) {
  return <StyledLinkButton variant={variant} {...props} />;
}

LinkButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['', 'lg', 'sm', 'xs']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger']),
};
