import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import RadioButton from './RadioButton';
import ValidationMessage from './ValidationMessage';

const Legend = styled.legend`
  ${(props) => props.hasErrors && 'color: var(--danger-color);'}
`;

export default function RadioButtonGroup({ children, errors = [], options, value, ...props }) {
  const error = errors.find((e) => e.field === (props.field || props.name));

  const renderRadioButton = (option) => (
    <RadioButton
      {...props}
      checked={option[1] === value}
      hasErrors={error !== undefined}
      id={`${props.id}_${option[1]}`}
      key={`${props.id}_${option[1]}`}
      value={option[1]}
    >
      {option[0]}
    </RadioButton>
  );

  return (
    <fieldset>
      <Legend hasErrors={error !== undefined}>{children}</Legend>
      {options.map(renderRadioButton)}
      {error !== undefined && <ValidationMessage errors={error.messages} />}
    </fieldset>
  );
}

RadioButtonGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  field: (props, propName, componentName) => {
    if (
      props.errors.length > 0 &&
      (props.name === undefined || typeof props.name !== 'string') &&
      (props.field === undefined || typeof props.field !== 'string')
    ) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Field is required when errors are present`
      );
    }
  },
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      messages: PropTypes.array,
    })
  ),
  options: PropTypes.arrayOf(PropTypes.array),
};
