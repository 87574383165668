import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

const Container = styled.div`
  background-color: var(--body-bgcolor);
  border: 1px dashed var(--primary-color);
  border-radius: 4px;
  flex: 1;
  ${({ dragging }) => dragging && 'border-style: solid'}
`;

const FileInput = styled.input.attrs({ type: 'file' })`
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0.1px;
  top: 0;
  z-index: -1;
`;

const Label = styled.label`
  align-items: center;
  color: var(--primary-color);
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0.375rem 0.75rem;

  ${(props) =>
    props.hasErrors &&
    `
      background-color: var(--danger-color);
      color: var(--black);
  `}

  @media (min-width: 576px) {
    min-width: 210px;
  }
`;

const FileUpload = ({ children, errors = [], id = 'file', ...props }) => {
  const { t } = useTranslation('upload');

  const [dragging, setDragging] = useState(false);

  const textUploadBar = props.textUploadBar || t('clickOrDragToUpload');

  const error = errors.find((e) => e.field === (props.field || props.name));

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setDragging(false);
    props.onChange(Array.from(event.dataTransfer.files));
  };

  const onChange = ({ target: { files } }) => {
    props.onChange(Array.from(files));
  };

  return (
    <div>
      <Wrapper>
        <Container
          dragging={dragging}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <FileInput {...props} onChange={onChange} />
          <Label htmlFor={id} hasErrors={error !== undefined}>
            {children}
            {error === undefined ? textUploadBar : error.messages}
            <FontAwesomeIcon icon={faUpload} />
          </Label>
        </Container>
      </Wrapper>
    </div>
  );
};

FileUpload.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  field: (props, propName, componentName) => {
    if (
      props.errors.length > 0 &&
      (props.name === undefined || typeof props.name !== 'string') &&
      (props.field === undefined || typeof props.field !== 'string')
    ) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Field is required when errors are present`
      );
    }
  },
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      messages: PropTypes.array,
    })
  ),
};

export default FileUpload;
