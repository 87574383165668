import React, { useEffect, useReducer, useRef } from 'react';
import isHotkey from 'is-hotkey';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

import { Container, Badge, Input, Item, List } from './Components';
import ValidationMessage from '../Form/ValidationMessage';

import { reducer } from './reducer';

if (window.HTMLElement.prototype.scrollIntoView === undefined) {
  window.HTMLElement.prototype.scrollIntoView = function () {};
}

const StyledInput = styled(Input)`
  width: ${({ placeholder }) => placeholder && '100%'};

  ${(props) =>
    props.hasErrors &&
    !props.multiple &&
    `
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-position: right calc(0.375em + 0.1875rem) center;
      background-repeat: no-repeat;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      border-color: var(--danger-color);
      padding-right: calc(1.5em + 0.75rem);
  `}

  &::placeholder {
    color: hsl(218, 24%, 52%);
    opacity: 0.5;
  }
`;

const Wrapper = styled.div``;

const isDownKey = isHotkey('down', { byKey: true });
const isEnter = isHotkey('enter', { byKey: true });
const isUpKey = isHotkey('up', { byKey: true });
const isEscape = isHotkey('escape', { byKey: true });

export default function AutoComplete({
  disabled = false,
  displayFn = (item) => item.name,
  errors = [],
  items = [],
  multiple = false,
  selectedItemIds = [],
  value = '',
  ...props
}) {
  const input = useRef();
  const error = errors.find((e) => e.field === (props.field || props.name));

  let inputValue = value && items.find((item) => item.databaseId === value);
  inputValue = inputValue ? displayFn(inputValue) : '';

  const [state, dispatch] = useReducer(reducer, {
    filteredItems: items,
    focus: false,
    focusItem: {},
    inputValue,
  });

  const onBlur = () => dispatch({ type: 'onBlur' });

  const onChange = ({ target: { value } }) => {
    let filteredItems;

    if (value === '') {
      !multiple && props.onChange(props.name, null);
      filteredItems = [...items];
    } else {
      filteredItems = items.filter((item) => displayFn(item).toLowerCase().includes(value.toLowerCase()));
    }

    dispatch({
      type: 'inputChange',
      payload: { filteredItems, value },
    });
  };

  const onClick = () => input.current.focus();
  const onFocus = () => dispatch({ type: 'onFocus' });

  const onKeyDown = (event) => {
    if (state.filteredItems.length === 0) return;

    if (isUpKey(event)) {
      event.preventDefault();
      dispatch({ type: 'moveUp', payload: { selectedItemIds } });
    } else if (isDownKey(event)) {
      event.preventDefault();
      dispatch({ type: 'moveDown', payload: { selectedItemIds } });
    } else if (isEscape(event)) {
      event.stopPropagation();
      input.current.blur();
    } else if (isEnter(event)) {
      event.preventDefault();

      if (state.focusItem.databaseId) {
        onItemSelect(state.focusItem);
      }
    }
  };

  const onItemSelect = (item) => {
    if (multiple) {
      if (selectedItemIds.includes(item.databaseId)) {
        props.onChange(
          props.name,
          selectedItemIds.filter((i) => i !== item.databaseId)
        );
      } else {
        props.onChange(props.name, [...selectedItemIds, item.databaseId]);
      }
      dispatch({ type: 'inputChange', payload: { filteredItems: items, value: '' } });
    } else {
      dispatch({ type: 'inputChange', payload: { filteredItems: items, value: displayFn(item) } });
      props.onChange(props.name, item.databaseId);
    }

    input.current.blur();
  };

  const onDeselectItem = (item) => {
    props.onChange(
      props.name,
      selectedItemIds.filter((i) => i !== item.databaseId)
    );
  };

  const renderSelectedItem = (item) => (
    <Badge key={item.databaseId}>
      <Badge.Label>{displayFn(item)}</Badge.Label>
      <Badge.Button
        aria-label={`Delete ${displayFn(item)}`}
        onClick={onDeselectItem.bind(this, item)}
        tabIndex="-1"
        type="button"
      >
        <FontAwesomeIcon icon={faTimesCircle} />
      </Badge.Button>
    </Badge>
  );

  const renderFilterItem = (item) => (
    <Item
      focus={state.focusItem.databaseId === item.databaseId}
      id={`${props.name}-${item.databaseId}`}
      key={item.databaseId}
      onMouseDown={onItemSelect.bind(null, item)}
      role="button"
      tabIndex="-1"
    >
      {displayFn(item)}
    </Item>
  );

  useEffect(() => {
    if (state.focusItem.databaseId) {
      document.querySelector(`#${props.name}-${state.focusItem.databaseId}`).scrollIntoView({ block: 'nearest' });
    }
  }, [props.name, state.focusItem]);

  useEffect(() => {
    dispatch({ type: 'inputChange', payload: { filteredItems: items } });
  }, [items]);

  return (
    <Wrapper className={props.className}>
      <Container as="div" focus={state.focus} hasErrors={error !== undefined} onClick={onClick}>
        {multiple && items.filter((i) => selectedItemIds.includes(i.databaseId)).map(renderSelectedItem)}
        <StyledInput
          autoComplete="off"
          disabled={disabled}
          hasErrors={error !== undefined}
          id={props.id}
          multiple={multiple}
          name={props.name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          placeholder={selectedItemIds.length === 0 ? props.placeholder : undefined}
          ref={input}
          type="text"
          value={state.value || ''}
        />
      </Container>
      <List hidden={!state.focus}>
        {state.filteredItems.filter((fi) => !selectedItemIds.includes(fi.databaseId)).map(renderFilterItem)}
      </List>
      {error !== undefined && <ValidationMessage errors={error.messages} />}
    </Wrapper>
  );
}

AutoComplete.propTypes = {
  disabled: PropTypes.bool,
  displayFn: PropTypes.func,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      messages: PropTypes.array,
    })
  ),
  field: (props, propName, componentName) => {
    if (
      props.errors.length > 0 &&
      (props.name === undefined || typeof props.name !== 'string') &&
      (props.field === undefined || typeof props.field !== 'string')
    ) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Field is required when errors are present`
      );
    }
  },
  items: PropTypes.arrayOf(
    PropTypes.shape({
      databaseId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string,
    })
  ).isRequired,
  id: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  selectedItemIds: PropTypes.array,
  value: PropTypes.any,
};
