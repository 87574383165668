import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StyledInput } from './Input';
import ValidationMessage from './ValidationMessage';

const StyledSelect = styled(StyledInput)`
  display: inline-block;
  backgroundi-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  padding-right: 2rem;

  ${(props) =>
    props.hasErrors &&
    `
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-position: right .75rem center,center right 2rem;
      background-size: 16px 12px,calc(.75em + .375rem) calc(.75em + .375rem);
      padding-right: 3.5rem;
`}

  &:focus {
    border-color: #aab1d5;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(85, 99, 170, 0.25);
  }

  // Hides the default caret in IE11
  &::-ms-expand {
    display: none;
  }

  // Remove outline from select box in FF
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057;
  }
`;

export default function Select({ errors = [], ...props }) {
  const error = errors.find((e) => e.field === (props.field || props.name));

  return (
    <>
      <StyledSelect as={'select'} hasErrors={error !== undefined} {...props} />
      {error !== undefined && <ValidationMessage errors={error.messages} />}
    </>
  );
}

Select.propTypes = {
  name: PropTypes.string,
  field: ({ errors = [], ...props }, propName, componentName) => {
    if (
      errors.length > 0 &&
      (props.name === undefined || typeof props.name !== 'string') &&
      (props.field === undefined || typeof props.field !== 'string')
    ) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Field is required when errors are present`
      );
    }
  },
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      messages: PropTypes.array,
    })
  ),
};
