import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const RadioButtonForm = styled.div`
  ${({ inline }) => inline && 'display: inline-block; margin-right: 1rem;'}
  padding-left: 1.5em;
`;

const Label = styled.label`
  display: inline-block;
  ${({ disabled }) => disabled && 'opacity: 0.5;'}
`;

const RadioButtonStyle = styled.input`
  appearance: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  float: left;
  height: 1em;
  margin-top: 0.25em;
  margin-left: -1.5em;
  transition: all 0.15s ease-in-out;
  transition-property: background-color, background-position, border-color, box-shadow;
  width: 1em;

  &:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
  }
  &:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  ${(props) => props.hasErrors && 'border-color: var(--danger-color);'}
`;

export default function RadioButton({ children, inline = false, ...props }) {
  return (
    <RadioButtonForm inline={inline}>
      <RadioButtonStyle {...props} type="radio" />
      <Label disabled={props.disabled} htmlFor={props.id}>
        {children}
      </Label>
    </RadioButtonForm>
  );
}

RadioButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  id: PropTypes.string.isRequired,
  inline: PropTypes.bool,
};
